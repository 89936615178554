exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-blog-category-js": () => import("./../../../src/templates/blog/blog-category.js" /* webpackChunkName: "component---src-templates-blog-blog-category-js" */),
  "component---src-templates-blog-blog-js": () => import("./../../../src/templates/blog/blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-blog-blog-post-js": () => import("./../../../src/templates/blog/blog-post.js" /* webpackChunkName: "component---src-templates-blog-blog-post-js" */),
  "component---src-templates-blog-top-stories-js": () => import("./../../../src/templates/blog/top-stories.js" /* webpackChunkName: "component---src-templates-blog-top-stories-js" */),
  "component---src-templates-pages-ambassador-js": () => import("./../../../src/templates/pages/ambassador.js" /* webpackChunkName: "component---src-templates-pages-ambassador-js" */),
  "component---src-templates-pages-b-2-b-js": () => import("./../../../src/templates/pages/b2b.js" /* webpackChunkName: "component---src-templates-pages-b-2-b-js" */),
  "component---src-templates-pages-black-week-js": () => import("./../../../src/templates/pages/black-week.js" /* webpackChunkName: "component---src-templates-pages-black-week-js" */),
  "component---src-templates-pages-checkout-js": () => import("./../../../src/templates/pages/checkout.js" /* webpackChunkName: "component---src-templates-pages-checkout-js" */),
  "component---src-templates-pages-christmas-deals-js": () => import("./../../../src/templates/pages/christmas-deals.js" /* webpackChunkName: "component---src-templates-pages-christmas-deals-js" */),
  "component---src-templates-pages-compare-js": () => import("./../../../src/templates/pages/compare.js" /* webpackChunkName: "component---src-templates-pages-compare-js" */),
  "component---src-templates-pages-cyber-monday-js": () => import("./../../../src/templates/pages/cyber-monday.js" /* webpackChunkName: "component---src-templates-pages-cyber-monday-js" */),
  "component---src-templates-pages-deals-js": () => import("./../../../src/templates/pages/deals.js" /* webpackChunkName: "component---src-templates-pages-deals-js" */),
  "component---src-templates-pages-failed-js": () => import("./../../../src/templates/pages/failed.js" /* webpackChunkName: "component---src-templates-pages-failed-js" */),
  "component---src-templates-pages-gift-card-js": () => import("./../../../src/templates/pages/gift-card.js" /* webpackChunkName: "component---src-templates-pages-gift-card-js" */),
  "component---src-templates-pages-home-js": () => import("./../../../src/templates/pages/home.js" /* webpackChunkName: "component---src-templates-pages-home-js" */),
  "component---src-templates-pages-member-js": () => import("./../../../src/templates/pages/member.js" /* webpackChunkName: "component---src-templates-pages-member-js" */),
  "component---src-templates-pages-our-story-js": () => import("./../../../src/templates/pages/our-story.js" /* webpackChunkName: "component---src-templates-pages-our-story-js" */),
  "component---src-templates-pages-returns-js": () => import("./../../../src/templates/pages/returns.js" /* webpackChunkName: "component---src-templates-pages-returns-js" */),
  "component---src-templates-pages-shop-js": () => import("./../../../src/templates/pages/shop.js" /* webpackChunkName: "component---src-templates-pages-shop-js" */),
  "component---src-templates-pages-standard-js": () => import("./../../../src/templates/pages/standard.js" /* webpackChunkName: "component---src-templates-pages-standard-js" */),
  "component---src-templates-pages-thankyou-js": () => import("./../../../src/templates/pages/thankyou.js" /* webpackChunkName: "component---src-templates-pages-thankyou-js" */),
  "component---src-templates-pages-tracking-js": () => import("./../../../src/templates/pages/tracking.js" /* webpackChunkName: "component---src-templates-pages-tracking-js" */),
  "component---src-templates-pages-vip-js": () => import("./../../../src/templates/pages/vip.js" /* webpackChunkName: "component---src-templates-pages-vip-js" */),
  "component---src-templates-pages-vip-new-js": () => import("./../../../src/templates/pages/vip-new.js" /* webpackChunkName: "component---src-templates-pages-vip-new-js" */),
  "component---src-templates-product-product-js": () => import("./../../../src/templates/product/product.js" /* webpackChunkName: "component---src-templates-product-product-js" */)
}

